@font-face {
    font-family: GTSuperDisplayTrial;
    font-weight: 100;
    src: url("../assets/fonts/GT-Super-Display-Trial-Light.otf")
        format("opentype");
}

@font-face {
    font-family: GTSuperDisplayTrial;
    font-weight: 300;
    src: url("../assets/fonts/GT-Super-Display-Trial-Regular.otf")
        format("opentype");
}

@font-face {
    font-family: GTSuperDisplayTrial;
    font-weight: 500;
    src: url("../assets/fonts/GT-Super-Display-Trial-Medium.otf")
        format("opentype");
}

@font-face {
    font-family: GTSuperDisplayTrial;
    font-weight: 700;
    src: url("../assets/fonts/GT-Super-Display-Trial-Bold.otf")
        format("opentype");
}

@font-face {
    font-family: GTSuperDisplayTrial;
    font-weight: 900;
    src: url("../assets/fonts/GT-Super-Display-Trial-Super.otf")
        format("opentype");
}
