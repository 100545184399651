@import url("styles/fonts.css");

html,
body {
    /*background: linear-gradient(135deg, #fccca2 0%, #fc8028 100%);*/
    background: linear-gradient(135deg, #ffecd2 0%, #fcb69f 100%);
    /*background-size: 20px 20px;*/
    width: 100vw;
    height: 100vh;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
    font-family: GTSuperDisplayTrial;
}

.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

.App-content {
    position: fixed;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: flex-end;
    box-sizing: border-box;
}

.App-content .banner {
    font-family: GTSuperDisplayTrial;
    font-weight: 900;
    font-size: 12rem;
    text-align: left;
    padding: 0 0 48px 16px;
    line-height: 10rem;
    color: #000;
}

.App-content .App-contact {
    display: none;
}

@media (max-width: 760px) {
    .App-content {
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
    }

    .App-content .banner {
        font-size: 5rem;
        font-weight: bold;
        text-align: left;
        padding: 0 0 0 16px;
        line-height: 4.5rem;
        color: #000;
    }

    .App-content .App-contact {
        display: inline-block;
        margin-left: 16px;
    }
}

.App-link {
    color: #61dafb;
}
